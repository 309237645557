export const ROUTES = {
  ADMIN: '/admin',
  ACCOUNT: '/account', // Deprecated
  ACCOUNT_EVENTS: '/account/events',
  ACCOUNT_HELP: '/account/help',
  ACCOUNT_ORDERS: '/account/orders',
  ACCOUNT_USER: '/account/user',
  EVENT: '/event',
  LOGIN: '/login',
  REGISTER: '/register',
  ACCOUNT_CONNECTORS: '/account/connectors',
  EVENT_CREATE: '/event/new',
  EVENT_ORDER: '/account/events/order',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  HELP_FAQ: '/help-faq',
  ABOUT: '/about',
  FORGOT_PASSWORD: '/reset-password',
  PRICES: '/prices',
  HOW_IT_WORKS: '/how-it-works',
  CONTENT_PAGE_WEDDING: '/wedding',
  CONTENT_PAGE_WEDDING_ANNIVERSARY: '/wedding-anniversary',
  CONTENT_PAGE_COMPANY_OUTING: '/company-outing',
  CONTENT_PAGE_BIRTHDAY: '/birthday',
  CONTENT_PAGE_CHRISTMAS: '/christmas',
  CONTENT_PAGE_END_OF_YEAR: '/end-of-year',
  CONTENT_PAGE_SPORT_EVENT: '/sport-event',
  CONTENT_PAGE_VACATION: '/vacation',
  CONTENT_PAGE_FAMILY_GATHERING: '/family-gathering',
}
